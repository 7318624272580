@use 'sass:map';

@use '@shared/styles/config/variables' as *;

.root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  backdrop-filter: saturate(180%) blur(5px);
  background: hsla(0, 0%, 100%, .8);
  z-index: 500;
  overflow: hidden;
  gap: map.get($spacers, 'spacing', '4');
  
  [class*="Loader_loader__"] {
    > div {
      background: map.get($colors, 'purple-rain');
    }
  }
}