@use 'sass:map';

@use '@shared/styles/config/variables' as *;


.loader {
  display: inline-block;
  position: relative;
  width: map.get($spacers, 'spacing', '12');

  div {
    position: absolute;
    width: map.get($spacers, 'spacing', '4');
    height: map.get($spacers, 'spacing', '4');
    border-radius: 50%;
    top: -0.5rem;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: map.get($spacers, 'spacing', '2');
      animation: loaderAnimation1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 8px;
      animation: loaderAnimation2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 2rem;
      animation: loaderAnimation2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 3.5rem;
      animation: loaderAnimation3 0.6s infinite;
    }
  }
}


@keyframes loaderAnimation1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loaderAnimation3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loaderAnimation2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1.5rem, 0);
  }
}
